import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "@material-ui/core/Button";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1>Privacy policy</h1>

    <h2>External parties</h2>
    <p>
      The aplication uses Google Firebase to gather information how the users
      interact with the app. By using our app, you agree that your data will be
      processed and stored by Google LLC.
    </p>
    <h2>Private data storage</h2>
    <p>
      The application does not send any kind of information that could be used
      to unambigously identify user. Hence, all private data resides on the
      device only.
    </p>

    <h2>Anonymous storage</h2>
    <p>
      Aside from the data being sent to the external parties, the application
      will send some diagnostic data to our servers. We will be using it to
      assess the manner in which the application is being used. This includes
      the pose of the device, placed virtual objects and the current tracking
      status. The data will not include any information which allows for
      unambigous user identification. Moreover, we will not send any data which
      is strictly not necessary for assessing the interaction with our app.
      {/* The polls which are filled on our website will be stored within our
      company. We will use this information for the following purposes:
      <ul>
        <li>assessing market needs &amp; pricing</li>
        <li>estimating market segments</li>
        <li>
          contacting to deliver the requested information about the released
          products
        </li>
      </ul> */}
    </p>

    <h2>Right to be forgotten</h2>
    <p>
      If you wish to remove all personal data collected by the application,
      simply uninstall it. All anonymous data is not tied to the app install,
      hence it is not possible for us to link your removal request with this
      collected anonymous data. Therefore, the usage data will remain on our
      server for as long as it can help us to gather insights.
    </p>

    <div style={{ marginBottom: "1.45rem" }}>
      <a href="/">
        <Button variant="contained" color="black">
          Lear more about PanoRoom →
        </Button>
      </a>
    </div>
  </Layout>
);

export default PrivacyPolicy;
